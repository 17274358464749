import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_guards';
import { SiteLayoutComponent } from './_layout/site-layout/site-layout.component';
import { AppLayoutComponent } from './_layout/app-layout/app-layout.component';
import { ApiTestComponent } from './api-test/api-test.component';

const routes: Routes = [
	{
		path: 'main',
		canActivate: [ AuthGuard ],
		component: AppLayoutComponent,

		children: [
			{ path: '', redirectTo: 'dashboard', pathMatch: 'full' },
			{ path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
			{
				path: 'video-listing',
				loadChildren: () => import('./videoListing/videoListing.module').then(m =>m.VideoListingModule),
				data: { breadcrumb: 'Video', permission: 'list_category' }
			},
			{
				path: 'team-listing',
				loadChildren: () => import('./team/team.module').then(m =>m.TeamListingModule),
				data: { breadcrumb: 'Team', permission: 'list_category' }
			},
			{
				path: 'player-listing',
				loadChildren: () => import('./playerListing/playerListing.module').then(m =>m.PlayerListingModule),
				data: { breadcrumb: 'Player', permission: 'list_category' }
			},
			{
				path: 'xml-checker',
				loadChildren: () => import('./xml-checker/xml-checker.module').then(m =>m.XmlCheckerModule),
				data: { breadcrumb: 'xml-checker', permission: 'list_category' }
			},
			{
				path: 'basket-xml-checker',
				loadChildren: () => import('./basket-xml-checker/basket-xml-checker.module').then(m =>m.XmlCheckerModule),
				data: { breadcrumb: 'xml-checker', permission: 'list_category' }
			},
			{
				path: 'users',
				loadChildren: () => import('./users/users.module').then(m =>m.UsersModule),
				data: { breadcrumb: 'User', permission: 'list_category' }
			},
			{
				path: 'metaData/upload',
				loadChildren: () => import('./metData-uploader/metData-uploader.module').then(m =>m.MetaDataModule),
				data: { breadcrumb: 'MetaData', permission: 'list_category' }
			},
			{
				path: 'metaData/generate',
				loadChildren: () => import('./metaData-Generator/metaDataGenerator.module').then(m =>m.MetaDataGeneratorModule),
				data: { breadcrumb: 'MetaDataListing', permission: 'list_category' }
			},
			{
				path: 'api/club',
				loadChildren: () => import('./club/club.module').then(m =>m.ClubListingModule),
				data: { breadcrumb: 'MetaDataListing', permission: 'list_category' }
			},
			{
				path: 'action',
				loadChildren: () => import('./action/action.module').then(m =>m.ActionListingModule),
				data: { breadcrumb: 'ActionListing', permission: 'list_category' }
			},
			{
				path: 'filteredPlayers',
				loadChildren: () => import('./filteredPlayers/filteredPlayers.module').then(m =>m.FilterPlayersModule),
				data: { breadcrumb: 'FilteredPlayers', permission: 'list_category' }
			},
			{
				path: 'stats',
				loadChildren: () => import('./stats/stats.module').then(m =>m.StatsModule),
				data: { breadcrumb: 'FilteredPlayers', permission: 'list_category' }
			},
			{
				path: 'videolist',
				loadChildren: () => import('./aws-VideoList/videoList.module').then(m =>m.VideoListModule),
				data: { breadcrumb: 'FilteredPlayers', permission: 'list_category' }
			},
			{
				path: 'jobList',
				loadChildren: () => import('./jobListing/jobListing.module').then(m =>m.JobListModule),
				data: { breadcrumb: 'FilteredPlayers', permission: 'list_category' }
			},
			{
				path: 'fixtures',
				loadChildren: () => import('./fixtures/fixtures.module').then(m =>m.FixtureListingModule),
				data: { breadcrumb: 'fixture', permission: 'list_category' }
			},
		
			{
				path: 'api/addEvent',
				loadChildren: () => import('./addEvent/addEvent.module').then(m =>m.AddModuleModule),
				data: { breadcrumb: 'addEvent', permission: 'list_category' }
			},
			{
				path: 'event-catche',
				loadChildren: () => import('./event_catche/event_catche.module').then(m =>m.EventCatcheListingModule),
				data: { breadcrumb: 'catche', permission: 'list_category' }
			},
			{
				path: 'categories',
				loadChildren: () => import('./categories/categories.module').then(m => m.CategoriesModule),
				data: { breadcrumb: 'Category', permission: 'list_category' }
			},
			{
				path: 'brand',
				loadChildren: () => import('./brand/brand.module').then(m => m.BrandModule),
				data: { breadcrumb: 'Brand', permission: 'list_brand' }
			},
			{
				path: 'products',
				loadChildren: () => import('./products/products.module').then(m => m.ProductsModule),
				data: { breadcrumb: 'Product', permission: 'list_product' }
			},
			{
				path: 'roles',
				loadChildren: () => import('./roles/roles.module').then(m => m.RolesModule),
				data: { breadcrumb: 'Roles', permission: 'list_roles' }
			},
			{
				path: 'capabilities',
				loadChildren: () => import('./capabilities/capabilities.module').then(m => m.CapabilitiesModule),
				data: {
					breadcrumb: 'Capabilities',
					permission: 'list_capabilities'
				}
			},
			{
				path: 'email',
				loadChildren: () => import('./resources/email/email.module').then(m => m.EmailModule),
				data: { breadcrumb: 'Email', permission: 'list_systememails' }
			},
			{
				path: 'pages',
				loadChildren: () => import('./resources/pages/pages.module').then(m => m.PagesModule),
				data: { breadcrumb: 'Static Page', permission: 'list_page' }
			},
			{
				path: 'faq',
				loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule),
				data: { breadcrumb: 'FAQs', permission: 'list_faq' }
			},
			{
				path: 'settings',
				loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
				data: { breadcrumb: 'Settings', permission: 'update_setting' }
			},
			{ 
				path: 'syncing-tool',loadChildren: () => import('./syncing-tool/syncing.module').then(m => m.SyncingToolModule),
				data: { breadcrumb: 'syncing-tool', permission: 'update_setting' }
			},
			{
				path: 'configurations',
				loadChildren: () => import('./configurations/configurations.module').then(m => m.ConfigurationsModule),
				data: { breadcrumb: 'configurations', permission: 'update_setting' }
			},
			{
				path: 'parser',
				loadChildren: () => import('./parser-setting/parser-setting.module').then(m => m.ParserSettingModule),
				data: { breadcrumb: 'parser', permission: 'update_setting' }
			},
			{
				path: 'analytics',
				loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule),
				data: { breadcrumb: 'analytics', permission: 'update_setting' }
			},
			{
				path: 'g-analytics',
				loadChildren: () => import('./google-analytics/analytics.module').then(m => m.GoogleAnalyticsModule),
				data: { breadcrumb: 'analytics', permission: 'update_setting' }
			},
			{
				path: 'embed',
				loadChildren: () => import('./embed/embed.module').then(m => m.EmbedModule),
				data: { breadcrumb: 'embed', permission: 'update_setting' }
			},
			{
				path: 'whitelistDomain',
				loadChildren: () => import('./whitelist-domain/whitelist-domain.module').then(m => m.WhitelistDomainModule),
				data: { breadcrumb: 'whitelistDomain', permission: 'update_setting' }
			},
			{
				path: 'viber-mesenger',
				loadChildren: () => import('./viber-messenger/viber-messenger.module').then(m => m.ViberMessengerModule),
				data: { breadcrumb: 'viber-mesenger', permission: 'list_category' }
			},
			{
				path: 'system',
				loadChildren: () => import('./system/system.module').then(m => m.SystemModule),
				data: { breadcrumb: 'system', permission: 'update_setting' }
			},
		]
	},
	{
		path: '',
		component: SiteLayoutComponent,
		children: [ { path: '', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) } ]
	},
	{
		path: 'SSO',
		component: SiteLayoutComponent,
		children: [ { path: '', loadChildren: () => import('./SSO/SSO.module').then(m => m.SSOModule) } ]
	},
	{
		path: 'home',
		component: SiteLayoutComponent,
		children: [ { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) } ]
	},
	{
		path: 'api-test',
		component: ApiTestComponent,
	},
	
	{
		path: '**',
		loadChildren: () => import('./page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
	}

	//{ path: 'login', loadChildren: './login/login.module#LoginModule' }
];

@NgModule({
	imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
	exports: [ RouterModule ]
})
export class AppRoutingModule {}
