<header>
  <div class="header-home">
    <div class="header-home-inner">
      <div class="row mx-0">
        <div class="col-lg-3">
          <div class="logo-cnt cursor-pointer" (click)="gotoHome()"><img
              src="assets/images/sportsvision-logo-white.png" />
          </div>
        </div>
        <!-- Login form design start -->
        <!-- <div class="col-lg-9 mx-auto">
          </div> -->
      </div>
    </div>
  </div>
</header>