import { Component, OnInit } from '@angular/core';
import {ApiTestServiceService} from './api-test-service.service'
import * as $ from "jquery";

@Component({
  selector: 'app-api-test',
  templateUrl: './api-test.component.html',
  styleUrls: ['./api-test.component.scss']
})
export class ApiTestComponent implements OnInit {

  constructor(private opta:ApiTestServiceService) { }

  ngOnInit(): void {
  }
  

  submit(url){
    // $.ajax({
    //   //url: "../assets/metadata/Liverpool_v_Wolverhampton_Wanderers_F24.xml",
    //   //url: "../assets/metaData/"+this.file_name,
    //   url: url,
  
    //   type: "GET",
    //   dataType: "XML",
    //   success: function (data) {
    //     console.log(data)
  
  
    //   }
    // });
    this.opta.getOptaDetail(url).subscribe((data) => {console.log("data is commimg ",data)},err => {
      console.log("error is comming",err)
    })
    console.log(url)
  }



}
