import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ApiTestServiceService {
  // private httpClient: HttpClient;
  // constructor(httpBackend: HttpBackend) {
  //   this.httpClient = new HttpClient(httpBackend);
  //  }

  //  private httpClient: HttpClient;
   constructor(private httpService: HttpClient) {
     
    }

  // getOptaDetail(url): Observable<any> {
	// 	return this.httpService.(url);
	// }
  getOptaDetail(data) {
		return this.httpService.post('/opta/apiTest', {ur:data});
	}
 
}
