<div class="left-sidebar" [ngClass]="{ mini: menu.isMini }">
  <div class="logo-cnt">
    <div class="logo-cnt-inner"></div>
  </div>

  <div class="left-menu-cnt">
    <perfect-scrollbar>


      <!-- Admin Menu start -->
      <ul>
        <li *ngIf="showDahsboard">
          <div class="inner-li" 
          [routerLink]="['/main/dashboard']" 
          routerLinkActive="active"
          [tooltip]="menu.isMini ? 'Dashboard' : ''" 
          placement="right" 
          container="body">
            <i class="fa fa-tachometer"></i><span>Dashboard</span>
          </div>
        </li>

        <!-- <li>
          <div
            class="inner-li"
            [routerLink]="['/main/g-analytics']"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? 'Analytics-Dashboard' : ''"
            placement="right"
            container="body"
          >
          <i class="fa fa-tachometer"></i><span>Analytics-Dashboard</span>
          </div>
        </li>
        <li>
          <div
            class="inner-li"
            [routerLink]="['/main/g-analytics/audience']"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? 'Analytics-Audience' : ''"
            placement="right"
            container="body"
          >
          <i class="fa fa-tachometer"></i><span>Analytics-Audience</span>
          </div>
        </li>
        <li>
          <div
            class="inner-li"
            [routerLink]="['/main/g-analytics/players']"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? 'Analytics-Players' : ''"
            placement="right"
            container="body"
          >
          <i class="fa fa-tachometer"></i><span>Analytics-Players</span>
          </div>
        </li>
        <li>
          <div
            class="inner-li"
            [routerLink]="['/main/g-analytics/games']"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? 'Analytics-Games' : ''"
            placement="right"
            container="body"
          >
          <i class="fa fa-tachometer"></i><span>Analytics-Games</span>
          </div>
        </li> -->

        <li *ngFor="let menu of accessibleMenu; let i = index" >
          <a 
          *ngIf="!menu.submenu.length" 
          (click)="subMenuToggle(i)"
          class="inner-li" 
          [routerLink]="[menu.parentRoute]" 
          routerLinkActive="active"
          [tooltip]="menu.isMini ? menu.menuName : ''" 
          placement="right" 
          container="body">
            <i class={{menu.class}}></i><span>{{menu.menuName}}</span>
          </a>
          <a
            class="inner-li sub-menu-outer"
            *ngIf="menu.submenu.length"
            (click)="subMenuToggle(i)"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? menu.menuName : ''" 
            container="body">
            <i class={{menu.class}}></i><span>{{menu.menuName}}</span>
          </a>
          <ul class="sub-menu" [ngClass]="{ 'show-menu': menu.menu }">
            <li *ngFor="let item of accessibleMenu[i].submenu">
              <a 
              [ngClass]="{ 'show-menu': item.menu }"
              class="inner-li" 
              [routerLink]="[item.route]" 
              routerLinkActive="active"
              [tooltip]="item.isMini ? item.menuName : ''" 
              placement="right" 
              container="body">
                <i class={{item.class}}></i><span>{{item.menuName}}</span>
              </a>
            </li>
          </ul>
        </li>

        <!-- <li let menu of accessibleMenu>
          <a
            class="inner-li"
            [routerLink]="[menu.parentRoute]"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? menu.menuName : ''" 
            placement="right"
            container="body"
          >
          <i class={{item.class}}></i><span>{{item.menuName}}</span>
          </a>
        </li>
        <li>
          <div
            class="inner-li"
            [routerLink]="['/main/player-listing']"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? 'Players' : ''"
            placement="right"
            container="body"
          >
          <i class="fa fa-users"></i><span>Players</span>
          </div>
        </li>

        <li>
          <a
            class="inner-li"
            [routerLink]="['/main/fixtures']"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? 'Players' : ''"
            placement="right"
            container="body"
            href="" 
          >
          <i class="fa fa-plus"></i><span>Fixtures</span>
          </a>
        </li>

        <li>
          <a
            class="inner-li"
            [routerLink]="['/main/videolist']"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? 'videolist' : ''"
            placement="right"
            container="body"
          >
          
          <i class="fa fa-file-video-o"></i><span>Aws-Videos</span>

          </a>
        </li>

        <li>
          <a
            class="inner-li"
            [routerLink]="['/main/jobList']"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? 'jobList' : ''"
            placement="right"
            container="body"
          >
          <i class="fa fa-amazon"></i><span>Aws-Jobs</span>
          </a>
        </li>
        <li>
          <a
            class="inner-li"
            [routerLink]="['/main/xml-checker']"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? 'Xml-checker' : ''"
            placement="right"
            container="body"
          >
          <i class="fa fa-search-plus"></i><span>XML-Checker</span>
          </a>
        </li>

        <li>
          <div
            class="inner-li"
            [routerLink]="['/main/basket-xml-checker']"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? 'Xml-checker' : ''"
            placement="right"
            container="body"
          >
          <i class="fa fa-search-plus"></i><span>BasketBall-XML-Checker</span>
          </div>
        </li>

        <li>
          <a
            class="inner-li"
            [routerLink]="['/main/syncing-tool']"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? 'syncing-tool' : ''"
            placement="right"
            container="body"
          >
          <i class="fa fa-code-fork" aria-hidden="true"></i><span>Syncing Tool</span>
          </a>
        </li>
        
        <li>
          <div
            class="inner-li"
            [routerLink]="['/main/metaData/generate']"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? 'MetaData-Generator' : ''"
            placement="right"
            container="body"
          >
          <i class="fa fa-plus" aria-hidden="true"></i><span>MetaData-Generator</span>

          </div>
        </li>


        <li>
          <div
            class="inner-li"
            [routerLink]="['/main/users']"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? 'Match' : ''"
            placement="right"
            container="body"
          >
          <i class="fa fa-user-secret" aria-hidden="true"></i><span>Users</span>
          </div>
        </li>

        <li>
          <a
            class="inner-li"
            [routerLink]="['/main/metaData/upload']"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? 'MetaData-Uploader' : ''"
            placement="right"
            container="body"
          >
          <i class="fa fa-upload" aria-hidden="true"></i><span>MetaData-Uploader</span>
          </a>
        </li>

        <li>
          <div
            class="inner-li"
            [routerLink]="['/main/action']"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? 'Actions' : ''"
            placement="right"
            container="body"
          >
          <i class="fa fa-cubes" aria-hidden="true"></i><span>Actions</span>
          </div>
        </li>

        <li>
          <a
            class="inner-li"
            [routerLink]="['/main/filteredPlayers']"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? 'Actions' : ''"
            placement="right"
            container="body"
          >
          <i class="fa fa-filter" aria-hidden="true"></i><span>Filtered Players</span>
          </a>
        </li>

        <li>
          <a
            class="inner-li"
            [routerLink]="['/main/stats']"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? 'Actions' : ''"
            placement="right"
            container="body"
          >
          <i class="fa fa-line-chart" aria-hidden="true"></i><span>stats</span>
          </a>
        </li>


        <li>
          <div
            class="inner-li"
            [routerLink]="['/main/settings']"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? 'Settings' : ''"
            placement="right"
            container="body"
          >
          <i class="fa fa-cog" aria-hidden="true"></i><span>Settings</span>
          </div>
        </li>

        <li>
          <div
            class="inner-li"
            [routerLink]="['/main/configurations']"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? 'Configurations' : ''"
            placement="right"
            container="body"
          >
          <i class="fa fa-cogs" aria-hidden="true"></i><span>Configurations</span>
          </div>
        </li>

        <li>
          <div
            class="inner-li"
            [routerLink]="['/main/analytics']"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? 'Analytics' : ''"
            placement="right"
            container="body"
          >
          <i class="fa fa-pie-chart" aria-hidden="true"></i><span>Analytics</span>
          </div>
        </li>

       <li>
          <div
            class="inner-li"
            [routerLink]="['/main/categories']"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? 'Category' : ''"
            placement="right"
            container="body"
          >
          <i class="fa fa-th-list"></i><span>Category</span>
          </div>
        </li>
       
         <li>
          <div
          class="inner-li"
          [routerLink]="['/main/roles']"
          routerLinkActive="active"
          [tooltip]="menu.isMini ? 'Roles' : ''"
          placement="right"
          container="body"
        >
        <i class="fa fa-address-book"></i><span>Roles</span>
        </div>
        </li>
        <li>
          <div
          class="inner-li"
          [routerLink]="['/main/capabilities']"
          routerLinkActive="active"
          [tooltip]="menu.isMini ? 'Capabilities' : ''"
          placement="right"
          container="body"
        >
        <i class="fa fa-braille"></i><span>Capabilities</span>
        </div>
        </li>
         <li>
          <div
          class="inner-li"
          [routerLink]="['/main/settings/company']"
          routerLinkActive="active"
          [tooltip]="menu.isMini ? 'Company' : ''"
          placement="right"
          container="body"
        >
        <i class="fa fa-briefcase"></i><span>Company</span>
        </div>
        </li>
         <li>
          <div
          class="inner-li"
          [routerLink]="['/main/email']"
          routerLinkActive="active"
          [tooltip]="menu.isMini ? 'Email Template' : ''"
          placement="right"
          container="body"
        >
        <i class="fa fa-at"></i><span>Email</span>
        </div>
        </li>
        <li>
            <div
            class="inner-li"
            [routerLink]="['/main/pages']"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? 'Static Pages' : ''"
            placement="right"
            container="body"
          >
          <i class="fa fa-file"></i><span>Static Pages</span>
          </div>
          </li>
          <li>
              <div
              class="inner-li"
              [routerLink]="['/main/faq']"
              routerLinkActive="active"
              [tooltip]="menu.isMini ? 'FAQ' : ''"
              placement="right"
              container="body"
            >
            <i class="fa fa-question"></i><span>FAQ</span>
            </div>
            </li> -->
      </ul>
      <!-- Admin Menu end -->
    </perfect-scrollbar>
  </div>
</div>