<div class="container">
  <form>
    <div class="form-group">
      <label for="getReq">Get Request</label>
      <input
        type="text"
        class="form-control"
        id="getReq"
        aria-describedby="emailHelp"
        placeholder="Enter Your Url"
        #getReqUrl
      />
      <small id="emailHelp" class="form-text text-muted"
        >Please enter get request url with all query parameters.</small
      >
    </div>
    

    <button type="submit" class="btn btn-primary" (click)="submit(getReqUrl.value)">Submit</button>
  </form>

<!-- 
  <form>
    <div class="form-group">
      <label for="getReq">Post Request</label>
      <input
        type="email"
        class="form-control"
        id="getReq"
        aria-describedby="emailHelp"
        placeholder="Enter Your Url"
      />
      <small id="emailHelp" class="form-text text-muted"
        >Please enter get request url with all query parameters.</small
      >
    </div>

    <button type="submit" class="btn btn-primary">Submit</button>
  </form> -->
</div>
