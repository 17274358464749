import { Component, OnInit } from '@angular/core';

import { UserService, CommonService } from 'src/app/_services';

@Component({
	selector: 'app-sidebar',
	templateUrl: './app-sidebar.component.html',
	styleUrls: [ './app-sidebar.component.scss' ]
})
export class AppSidebarComponent implements OnInit {
	menu: { isMini: boolean } = { isMini: false };
	isSubmenuActive = false;
	rmenu = false;
	userType = '';
	accessibleMenu=[]
	showDahsboard = true;
	constructor(private commonService: CommonService, private userService: UserService) {
		let userData = JSON.parse(localStorage.getItem('dx_user') || '')
		if(userData.role === "viber_analytics"){
			this.commonService.showDashboard = false;
		}else{
			this.commonService.showDashboard = true;
		}
		this.showDahsboard = this.commonService.showDashboard;
	}

	ngOnInit() {
		this.menu = this.commonService.menu;
		this.accessibleMenu = this.commonService.isMenuAccessible();
		//this.userType = this.userService.getUserInfo().type.toLocaleLowerCase();
	}
	subMenuToggle(i) {
		this.accessibleMenu.forEach((menu, index) => {
		  if (index !== i) {
			menu.menu = false; 
		  }
		});
		this.accessibleMenu[i].menu = !this.accessibleMenu[i].menu;
	  }
}
